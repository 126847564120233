import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const token = process.env.REACT_APP_API_KEY;
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    getTime: builder.query({
      query: () => "/v1/time",
    }),
    updateUser: builder.mutation({
      query: (obj) => ({
        url: "/v1/user/updateUser",
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const { useGetTimeQuery, useUpdateUserMutation } = apiSlice;
