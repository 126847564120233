import React from "react";
import "./App.css";

import { apiSlice } from "./services/apiSlice";

function App() {
  // const { data, error, isLoading } = useGetTimeQuery('bulbasaur');
  const { data, error, isLoading } = apiSlice.endpoints.getTime.useQuery("");
  const [updateUser, updateResult] = apiSlice.endpoints.updateUser.useMutation();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {"message" in error ? error.message : ""}</div>;

  const handleClick = () => {
    updateUser({ username: "test1" });
  };

  return (
    <div>
      <h1>Users</h1>
      <ul>
        GET:
        {data}
        <hr />
        POST:
        <button onClick={handleClick}>Update</button>
        <br />
        <span>is updating: {updateResult?.isLoading.toString()}</span>
        {updateResult?.isLoading && <div>Updating...</div>}
        <hr />
        <div>{JSON.stringify(updateResult)}</div>
        <hr />
        <div>isError {updateResult?.isError.toString()}</div>
        <div>data: <span>{JSON.stringify(updateResult?.data)}</span></div>

        <div>ENV VAR: {process.env.REACT_APP_TEST_VAR ?? "not set"}</div>
      </ul>
    </div>
  );
}

export default App;
